import { Card, Input } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { List } from 'react-bootstrap-icons'
import './style.css'

const Todo = () => {
    const [lists, setLists] = useState<string[]>(['1'])

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Input
                    style={{
                        marginTop: 50,
                        width: 400,
                    }}
                    placeholder={`请输入将要处理的清单`}
                    allowClear
                    onPressEnter={(e: any) => {
                        const { target, timeStamp } = e

                        console.log(target, timeStamp)

                        setLists([...lists, target?.value + ':' + moment(timeStamp).format('YYYY-MM-DD HH:ss:dd')])
                    }}
                />
            </div>

            <Card
                style={{
                    margin: 8,
                    height: 400,
                }}>
                <div className='todolist'>
                    {lists?.map((ci, index) => (
                        <span key={index + '_list'}>
                            <List />
                            {ci}
                        </span>
                    ))}
                </div>
            </Card>
        </>
    )
}

export default Todo
