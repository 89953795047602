import { useState } from 'react'
import { Github, Globe } from 'react-bootstrap-icons'
import './style.css'

const Blog = () => {
    const [collapseStatus, setCollapseStatus] = useState<boolean>(false)

    // menu的收缩
    const menuCollapse = () => {
        const menu_sider: HTMLElement = document.querySelector('.blog_menu_sider')
        const content_sider: HTMLElement = document.querySelector('.blog_content_sider')

        if (!collapseStatus) {
            menu_sider.classList.add('blog_menu_sider_close')
            content_sider.classList.add('blog_content_sider_expand')

            setCollapseStatus(true)
        } else {
            menu_sider.classList.remove('blog_menu_sider_close')
            content_sider.classList.remove('blog_content_sider_expand')
            // console.log(menu_sider.classList, content_sider.classList);
            setCollapseStatus(false)
        }
    }
    return (
        <>
            <div className='blog_container'>
                <section className='blog_menu_sider'>
                    <div className='blog_menu_inner'>
                        <header className='blog_menu_header'>Menu</header>
                        <ul>
                            <li>Home</li>
                            <li>About Me</li>
                            <li>Resume</li>
                            <li>My Life</li>
                            <li>Work Life</li>
                        </ul>

                        <hr />

                        <nav className='blog_menu_infos'>
                            <header>
                                <h3>About Me</h3>
                            </header>
                            <ul>
                                <li>Moxi</li>
                                <li>Software Engineer</li>
                                <li>fwx5618177@gmail.com</li>
                                <li>
                                    <a href='https://github.com/fwx5618177'>Github</a>
                                </li>
                            </ul>
                        </nav>

                        <footer>©Moxi</footer>
                    </div>
                </section>

                <section className='blog_content_sider'>
                    <header>
                        <div className='blog_content_collaspe' onClick={menuCollapse}></div>
                        <div className='blog_content_title'>
                            <h3>Blog</h3>
                            <div className='blog_content_ul'>
                                <Globe />
                                <ul>
                                    <li>
                                        <Github />
                                    </li>
                                    <li>1</li>
                                    <li>1</li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <section className='blog_content_content'>
                        <header>
                            <h3>Recent Post</h3>
                        </header>

                        <section className='blog_content_post'>
                            <article className='blog_content_article'>1</article>
                            <article className='blog_content_article'>1</article>
                        </section>
                    </section>
                    <footer>
                        <nav className='blog_content_pagnition' role={'navigation'}>
                            <span>Page 1 of 1</span>
                        </nav>
                    </footer>
                </section>
            </div>
        </>
    )
}

export default Blog
