/**
 * 404页面
 */
import './style.css'

const Error404 = () => {
    return (
        <>
            <div className='notFoundCOntainer'>
                <div className='notFound'>
                    <span>404</span>
                </div>
            </div>
        </>
    )
}

export default Error404
