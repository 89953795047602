/**
 * 组建的接口
 */

import { ReactNode } from 'react'

export interface MenuButton {
    label: string
    href: string
}

export interface BaseButton {
    icon?: HTMLElement
    label: string
    onClick?: () => void
}

export interface PurpleBtn {
    type?: 'default' | 'round' | 'circle' | 'transparent' | 'small' | 'regular' | 'large'
    status?: 'success' | 'error' | 'warning' | 'default' | 'danger' | 'neutral' | 'info' | 'primary'
    icon?: ReactNode
    children?: ReactNode
    // className?:string
    disabled?: boolean
}

export const TRANSPARENT_CLS = {
    transparent: 'transparentBtn_transparent',
}

export interface ITransparentBtn {
    type?: 'transparent'
    onClick?: () => void
    children?: ReactNode
}
