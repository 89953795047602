import { Button, Card, Col, Form, Input, Row } from 'antd'
import './style.css'

const ResumeEdite = () => {
    return (
        <>
            <Card
                title={
                    <h3
                        style={{
                            textAlign: 'center',
                        }}>
                        Resume Make
                    </h3>
                }
                bordered={false}
                style={{
                    margin: 8,
                }}>
                <section>
                    <div className='edite_form'>
                        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item wrapperCol={{ span: 16 }} key={'title'} label='Title'>
                                        <Input placeholder='please input your title' allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item wrapperCol={{ span: 16 }} key={'name'} label='Name'>
                                        <Input placeholder='please input your name' allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item wrapperCol={{ span: 16 }} key={'position'} label='Position'>
                                        <Input placeholder='Please input your position' allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item wrapperCol={{ span: 16 }} key={'experience'} label='Experience'>
                                        <Input placeholder='Please input your experience' allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item wrapperCol={{ span: 16 }} key={'experience'} label='Experience'>
                                        <Input placeholder='Please input your experience' allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type='primary' htmlType='submit'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className='edite_performance'></div>
                </section>
            </Card>
        </>
    )
}

export default ResumeEdite
