import './style.css'

const Infos = () => {
    return (
        <>
            <div className='infosBody'>
                <div className='start'>
                    <h3>Moxixii</h3>
                    <span>It's my pages</span>
                </div>
                <div className='se'>
                    <div>Moxi</div>
                    <div>123</div>
                </div>
                <div className='th'></div>
                <div className='for'></div>
                <div className='fif'></div>
            </div>
        </>
    )
}

export default Infos
