/**
 * Links的接口
 */

export enum LINKS_ENUM {
    DEFAULT = 'default',
    PRIMARY = 'primary',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
}

export const LINKS_CLASSNAME = {
    [LINKS_ENUM.DEFAULT]: 'Links_' + LINKS_ENUM.DEFAULT,
    [LINKS_ENUM.PRIMARY]: 'Links_' + LINKS_ENUM.PRIMARY,
    [LINKS_ENUM.INFO]: 'Links_' + LINKS_ENUM.INFO,
    [LINKS_ENUM.SUCCESS]: 'Links_' + LINKS_ENUM.SUCCESS,
    [LINKS_ENUM.WARNING]: 'Links_' + LINKS_ENUM.WARNING,
    [LINKS_ENUM.DANGER]: 'Links_' + LINKS_ENUM.DANGER,
}

export interface ILinks {
    type?: 'default' | 'primary' | 'info' | 'success' | 'warning' | 'danger'
    onClick?: React.MouseEventHandler
    children?: React.ReactNode
}
