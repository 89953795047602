import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n/index'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import Error404 from './pages/error/404'
import Loading from './pages/loading'
import Login from './pages/login'
import LayoutEle from './pages/dashboard/layout'
import User from './pages/dashboard/user'
import Default from './pages/dashboard/default'
import ArticlesEdite from './pages/dashboard/edite'
import RichText from './pages/dashboard/richText'
import Search from './pages/dashboard/articles/search'
import Todo from './pages/dashboard/todoList'
import MarkDown from './pages/dashboard/articles/markdown'
import MusicPlayer from './pages/dashboard/music'
import ErrorBoundary from './common/ErrorBoundary'
import Infos from './pages/infos/Layout'
import Author from './pages/dashboard/articles/author'
import Blog from './pages/blog/inde'
import DragCom from './pages/dashboard/drag'
import ResumeEdite from 'pages/dashboard/resume/edite'
import ResumeLook from 'pages/dashboard/resume/look'
// import { errorMonitor } from './common/error'
// import { performanceShow } from './common/performance'

// errorMonitor()
// performanceShow()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Suspense fallback={<div>Loading</div>}>
                <HashRouter>
                    <Routes>
                        <Route path='/' element={<App />} />
                        <Route path='/infos' element={<Outlet />}>
                            <Route index element={<Infos />} />
                        </Route>
                        <Route path='/blog' element={<Outlet />}>
                            <Route index element={<Blog />} />
                        </Route>
                        <Route path='/404' element={<Error404 />} />
                        <Route path='/loading' element={<Loading />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/dashboard' element={<LayoutEle />}>
                            <Route index element={<Default />} />
                            <Route path='user' element={<Outlet />}>
                                <Route index element={<Navigate to={'video'} />} />
                                <Route path='video' element={<User />} />
                            </Route>
                            <Route path='upload' element={'upload'} />
                            <Route path='articles' element={<Outlet />}>
                                <Route index element={<Navigate to={'search'} />} />
                                <Route path='search' element={<Search />} />
                                <Route path='edite' element={<ArticlesEdite />} />
                                <Route path='richText' element={<RichText />} />
                                <Route path='tags' element={'tags'} />
                                <Route path='preview' element={'preview'} />
                                <Route path='markdown' element={<MarkDown />} />
                                <Route path='author' element={<Author />} />
                            </Route>
                            <Route path='todolist' element={<Todo />} />
                            <Route path='mail' element={<Outlet />}>
                                <Route index element={<Navigate to={'setting'} />} />
                                <Route path='setting' element={'setting'} />
                            </Route>

                            <Route path='music' element={<MusicPlayer />} />
                            <Route path='drag' element={<DragCom />} />
                            <Route path='resume' element={<Outlet />}>
                                <Route index element={<Navigate to={'edite'} />} />
                                <Route path='edite' element={<ResumeEdite />} />
                                <Route path='look' element={<ResumeLook />} />
                            </Route>
                        </Route>
                        <Route path='*' caseSensitive={true} element={<Navigate to={'/404'} />} />
                    </Routes>
                </HashRouter>
            </Suspense>
        </ErrorBoundary>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
