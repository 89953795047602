import './style.css'

const RichText = () => {
    return (
        <>
            <div className='richEditor'>
                <div className='editeContainer'>
                    <div
                        className='edite'
                        contentEditable
                        onInput={e => {
                            // @ts-ignore
                            console.log(e, e.target, (e.target as HTMLElement)?.childNodes)
                            document.execCommand('bold', null, '<p></p>')
                            // e.stopPropagation()
                            e.preventDefault()
                        }}
                        onCut={e => {
                            console.log('cut:', e)
                        }}></div>

                    <div className='show'></div>
                </div>
            </div>
        </>
    )
}

export default RichText
