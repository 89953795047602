import './style.css'

const Login = () => {
    return (
        <>
            <div className='loginContainer'>
                <div className='form'>
                    <div className='login'>
                        <h3>Login</h3>
                        <div></div>
                    </div>

                    <div className='loginForm'></div>
                </div>
            </div>
        </>
    )
}

export default Login
