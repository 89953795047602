import { Card } from 'antd'

const User = () => {
    return (
        <>
            <Card
                style={{
                    margin: 12,
                }}>
                1
            </Card>
        </>
    )
}

export default User
