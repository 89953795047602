import { PurpleBtn } from './interface'

const PurPleBtn = (props: PurpleBtn) => {
    const { type, icon, children, status } = props

    let clsName = ''

    switch (type?.toLocaleLowerCase()) {
        case 'default':
            clsName = 'purpleBtn_default'
            break
        case 'round':
            clsName = 'purpleBtn_round'
            break
        case 'circle':
            clsName = 'purpleBtn_circle'
            break
        case 'transparent':
            clsName = 'purpleBtn_transparent'
            break
        case 'small':
            clsName = 'purpleBtn_small'
            break
        case 'regular':
            clsName = 'purpleBtn_default'
            break
        case 'large':
            clsName = 'purpleBtn_large'
            break
        default:
            clsName = 'purpleBtn_default'
            break
    }

    // status
    switch (status?.toLocaleLowerCase()) {
        case 'primary':
            break
        case 'default':
            clsName = 'purpleBtn_status_default'
            break
        case 'info':
            clsName = 'purpleBtn_status_info'
            break
        case 'success':
            clsName = 'purpleBtn_status_success'
            break
        case 'warning':
            clsName = 'purpleBtn_status_warning'
            break
        case 'danger':
            clsName = 'purpleBtn_status_danger'
            break
        case 'neutral':
            clsName = 'purpleBtn_status_neutral'
            break
        default:
            break
    }

    return (
        <>
            <button className={clsName}>
                {icon}
                <span>{children}</span>
            </button>
        </>
    )
}

export default PurPleBtn
