/**
 * 错误监控函数
 */

import html2canvas from 'html2canvas'
// import { capture } from "./screenshoot";

export const imgStack = []
export const bodyImgStack = []
export const rtcStack = []

// js错误监控
export const errorMonitor = () => {
    /**
     *@ param {string} message error message
     *@ param {string} source error file
     *@ param {number} lineno line number
     *@ param {number} colno column number
     *@ param {object} error object
     */
    window.onerror = (message, source, lineno, colno, error) => {
        console.error('catch onerror exception: ', [message, source, lineno, colno, error])

        return true
    }

    window.addEventListener(
        'error',
        err => {
            console.error('catch error exception: ', err)
        },
        true,
    )

    window.addEventListener('unhandledrejection', err => {
        err.preventDefault()
        console.error('catch unhandledrejection exception: ', err)
    })

    console.error = (...args) => {
        console.log(args)
        console.trace(args[1][4])
        console.count()

        console.log(imgStack.length, imgStack)
    }

    window.onclick = e => {
        html2canvas(document.body).then(canvas => {
            const img = canvas.toDataURL('image/png', 10)

            if (bodyImgStack.length >= 3) {
                bodyImgStack.shift()
            }

            bodyImgStack.push(img)
        })
        html2canvas(e.target).then(canvas => {
            const img = canvas.toDataURL('image/png', 10)

            if (imgStack.length >= 3) {
                imgStack.shift()
            }

            imgStack.push(img)
        })

        // rtcStack.push(capture())
    }

    // @ts-ignore
    console.errorStack = (...args) => {
        console.log(args)
        console.table(args)
    }
}
