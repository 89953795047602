/**
 * 过场动画
 */

// import './style.css'

const Loading = () => {
    return (
        <>
            <section>
                <div className='loader'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </section>
        </>
    )
}

export default Loading
