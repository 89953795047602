import { Button, message } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import './style.css'

const Author = () => {
    const [history, setHistory] = useState<[string, any][]>([])
    // 定时器
    const timer = []
    // 临时储存数据
    const tmpStore = ['']

    const handleChange = e => {
        const {
            // timeStamp,
            target,
        } = e
        // console.log(e, timeStamp)

        const per = document.querySelector('.text_performance')
        ;(per as unknown as HTMLElement).innerText = target.value
        // console.log(target.value, timeStamp)

        autoSave()
    }

    // 滚动设置
    // const syncScroller = (...args) => {
    //     const nodes = Array.prototype.filter.call(args, item => item instanceof HTMLElement)
    //     const max = nodes.length
    //     if (!max || max === 1) return
    //     let sign = 0 // 用于标注
    //     nodes.forEach((ele, index) => {
    //         ele.addEventListener('scroll', function () {
    //             // 给每一个节点绑定 scroll 事件
    //             if (!sign) {
    //                 // 标注为 0 时 表示滚动起源
    //                 sign = max - 1
    //                 const top = this.scrollTop
    //                 const left = this.scrollLeft
    //                 for (const node of nodes) {
    //                     // 同步所有除自己以外节点
    //                     if (node === this) continue
    //                     node.scrollTo(left, top)
    //                 }
    //             } else --sign // 其他节点滚动时 标注减一
    //         })
    //     })
    // }

    // 定时保存
    const autoSave = () => {
        const edite: HTMLTextAreaElement = document.querySelector('#edite_text')
        const value = edite.value
        if (timer.length > 0) {
            // console.log(value, Date.now(), timer)
            return
        }

        console.log(value.length - tmpStore[0].length, value.length, value)
        if (Math.abs(value.length - tmpStore[0].length) > 100) {
            tmpStore.pop()
            tmpStore.push(value)
            storeLocal(value)
        }

        const time = setInterval(() => {
            if (!!value) {
                // console.log(value, Date.now(), timer, window.localStorage)
                storeLocal(value)
            }
        }, 180000)

        if (timer.length > 0) {
            clearInterval(timer[0])
            timer.pop()
        }
        timer.push(time)
    }

    // 写入到localstorage中
    const storeLocal = (value: string) => {
        try {
            const localStore = window.localStorage
            const time = Date.now()
            const index = localStore.length

            window.localStorage.setItem(
                moment(time).format('YYYY-MM-DD HH:mm:ss'),
                JSON.stringify({
                    time,
                    value,
                }),
            )

            if (index >= 10) {
                const kV = Object.entries(localStore)
                const popV = kV.shift()
                localStore.removeItem(popV[0])
            }

            console.log('Object.entries(localStore):', Object.entries(localStore))

            setHistory(Object.entries(localStore))
        } catch (err) {
            message.error('自动本地保存失败!请检查权限!')
        }
    }

    return (
        <>
            <Button
                onClick={() => {
                    clearInterval(timer[0])
                    console.log(timer)
                }}>
                Stop
            </Button>
            <Button
                type='primary'
                onClick={() => {
                    window.localStorage.clear()
                }}>
                Clear
            </Button>
            <div className='text_container'>
                <textarea id='edite_text' spellCheck onChange={handleChange} className='text_area'></textarea>
                <div id='show_text' className='text_performance'></div>
            </div>

            <div className='text_old_version'>
                {history?.map((ci, index) => (
                    <div key={index + '_history'}>{ci[0]}</div>
                ))}
            </div>
        </>
    )
}

export default Author
