import { ILinks, LINKS_CLASSNAME } from './interface'

const LinksComponents = (props: ILinks) => {
    const { children, type } = props
    // @ts-ignore
    const clsName = LINKS_CLASSNAME?.[type.toLocaleLowerCase()]

    return <button className={clsName}>{children}</button>
}

export default LinksComponents
