import moment from 'moment'
import { Card, Col, Form, Input, Row, Select, DatePicker, Button, Table } from 'antd'
import { data, top100Films } from './_mock'
import { useForm } from 'antd/lib/form/Form'
import { columns } from './conf'

const { RangePicker } = DatePicker

const Search = () => {
    const [form] = useForm()

    const initialValues = {
        date: [moment().startOf('months'), moment().subtract(1, 'day').endOf('day')],
    }

    const disabledDate = current => {
        return current && current < moment().endOf('day')
    }

    return (
        <>
            <Card
                style={{
                    margin: 8,
                }}>
                <Form name='search' form={form} initialValues={initialValues} onFinish={v => console.log(v)} onFinishFailed={console.log}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={'title'} label='文章名'>
                                <Input bordered allowClear showCount size='middle' placeholder='请输入文章名' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name={'tags'} label='标签'>
                                <Select
                                    showArrow
                                    showSearch
                                    allowClear
                                    placeholder='请选择标签'
                                    filterOption={(_, ops) => {
                                        console.log(_, ops)
                                        return ops.children.toString().toLowerCase().includes(_.toLowerCase())
                                    }}>
                                    {top100Films?.map((ci, index) => (
                                        <Select.Option key={index + '_type'} value={ci?.year}>
                                            {ci?.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name='date' label='日期'>
                                <RangePicker showTime allowClear format={'YYYY-MM-DD HH:mm:ss'} disabledDate={disabledDate}></RangePicker>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <Button
                                style={{
                                    marginRight: 6,
                                }}
                                type='default'
                                htmlType='button'
                                onClick={() => form.resetFields()}>
                                Reset
                            </Button>
                            <Button type='primary' htmlType='submit'>
                                Search
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>

            <Card
                style={{
                    margin: 8,
                }}>
                <Table columns={columns()} dataSource={data} />
            </Card>
        </>
    )
}

export default Search
