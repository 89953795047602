import './style.css'

const MusicPlayer = () => {
    return (
        <>
            <iframe className='musicPlayer' src='https://music.163.com/'></iframe>
        </>
    )
}

export default MusicPlayer
