import React from 'react'
import {
    Linkedin,
    Github,
    Mailbox2,
    // Grid1x2Fill,
    // HeartFill,
    // PersonWorkspace
} from 'react-bootstrap-icons'
// import BaseBtn from "./common/button/BaseBtn";
// import MenuBtn from "./common/button/MenuBtn";
import PurPleBtn from './common/button/purple'
import TransparentBtn from './common/button/transparentBtn'
// import ContentComponents from "./common/content/Resume";
// import FrostedGlass from "./common/glass/frostedGlass";
import LinksComponents from './common/links'
import './style.css'
import { useTranslation } from 'react-i18next'
import SelectionEle from './common/select'
import { Link } from 'react-router-dom'

const MainPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className='navHeaders scrollBodyColor'>
                <div className='navHeadersTitle'>
                    <span>Moxixi React</span>
                </div>
                <div className='navHeadersMenu'>
                    <ul className='navListMenu'>
                        <li>{t('info')}</li>
                        <li>
                            <Link to='dashboard'>{t('blog')}</Link>
                        </li>
                        <li>{t('resume')}</li>
                        <li>{t('contact')}</li>
                        <li>
                            <SelectionEle />
                        </li>
                        <li>
                            <a href='https://www.linkedin.cn/incareer/in/wenxuan-feng-374a72114' target='_blank' rel='noreferrer'>
                                <Linkedin className='iconsLink scrollIconsLink' />
                            </a>
                        </li>
                        <li>
                            <a href='https://github.com/fwx5618177' target={'_blank'} rel='noreferrer'>
                                <Github className='iconsLink scrollIconsLink' />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='headerImage'>
                <div className='headerImage_next'>
                    <div>
                        <div></div>
                    </div>
                </div>
            </div>

            {/* <ContentComponents /> */}

            <div className='btnShow'>
                <PurPleBtn type={'default'} icon={<Mailbox2 />}>
                    default
                </PurPleBtn>
                <PurPleBtn type={'circle'}>circle</PurPleBtn>
                <PurPleBtn type={'round'} icon={<Mailbox2 />}>
                    round
                </PurPleBtn>
                <PurPleBtn type={'transparent'} icon={<Mailbox2 />}>
                    transparent
                </PurPleBtn>
                <PurPleBtn type={'small'} icon={<Mailbox2 />}>
                    small
                </PurPleBtn>
                <PurPleBtn type={'large'} icon={<Mailbox2 />}>
                    large
                </PurPleBtn>
                <PurPleBtn status={'default'} type={'default'} icon={<Mailbox2 />}>
                    default
                </PurPleBtn>
                <PurPleBtn status={'primary'} type={'default'} icon={<Mailbox2 />}>
                    primary
                </PurPleBtn>
                <PurPleBtn status={'info'} type={'default'} icon={<Mailbox2 />}>
                    info
                </PurPleBtn>
                <PurPleBtn status={'success'} type={'default'}>
                    Success
                </PurPleBtn>
                <PurPleBtn status={'warning'} type={'default'}>
                    warning
                </PurPleBtn>
                <PurPleBtn status={'danger'} type={'default'}>
                    danger
                </PurPleBtn>
                <PurPleBtn status={'neutral'} type={'default'}>
                    neutral
                </PurPleBtn>

                <LinksComponents type={'default'}>default</LinksComponents>
                <LinksComponents type={'primary'}>primary</LinksComponents>
                <LinksComponents type={'info'}>info</LinksComponents>
                <LinksComponents type={'success'}>success</LinksComponents>
                <LinksComponents type={'warning'}>warning</LinksComponents>
                <LinksComponents type={'danger'}>danger</LinksComponents>

                <TransparentBtn type={'transparent'}>Transparent</TransparentBtn>

                <div className='gross_container'>
                    <div className='gross'></div>
                    <h1>Test</h1>
                </div>
            </div>

            <div className='NavFooter'>
                <div>
                    <span>1</span>
                    <span>1</span>
                    <span>1</span>
                    <span>1</span>
                </div>
                <div>© 2022, made by Wenxuan feng</div>
            </div>
        </>
    )
}

export default MainPage
