import '@wangeditor/editor/dist/css/style.css' // 引入 css

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig } from '@wangeditor/editor'

const MyEditor = forwardRef(({ showData }: any, ref) => {
    const [editor, setEditor] = useState<IDomEditor | null>(null) // 存储 editor 实例
    const [html, setHtml] = useState('') // 编辑器内容

    useImperativeHandle(ref, () => ({
        getVal: html,
    }))

    const toolbarConfig = {}
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: 'Please Input somethings...',
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100 }}>
                <Toolbar editor={editor} defaultConfig={toolbarConfig} mode='default' style={{ borderBottom: '1px solid #ccc' }} />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        setHtml(editor.getHtml())
                        showData(editor.getHtml())
                    }}
                    mode='default'
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>
        </>
    )
})

export default MyEditor
