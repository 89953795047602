/**
 * select组件
 */
import i18n from '../../../../i18n/index'

const SelectionEle = () => {
    return (
        <select
            className='selection'
            defaultValue={'en'}
            onChange={_ => {
                console.log(_?.target?.value)
                i18n.changeLanguage(_?.target?.value)
            }}>
            <option value={'zh'}>中文</option>
            <option value={'en'}>English</option>
        </select>
    )
}

export default SelectionEle
