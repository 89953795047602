/**
 * En
 */

export const en_menu = {
    info: 'Info',
    blog: 'Blog',
    resume: 'Resume',
    contact: 'Contact',
}
