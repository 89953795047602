import { ITransparentBtn, TRANSPARENT_CLS } from './interface'

const TransparentBtn = (props: ITransparentBtn) => {
    const { children, type } = props
    // @ts-ignore
    const clsName = TRANSPARENT_CLS?.[type.toLocaleLowerCase()]

    return <button className={clsName}>{children}</button>
}

export default TransparentBtn
