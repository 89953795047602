import { Button } from 'antd'
import * as marked from 'marked'
import * as _ from 'lodash'
import { useEffect, useRef } from 'react'
import './style.css'

const MarkDown = () => {
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const showRef = useRef<HTMLDivElement>(null)
    const time = []

    const onChange = event => {
        const value = event.target.value
        const parsed = marked.parse(value)

        console.log(parsed)
        showRef.current.innerHTML = parsed

        if (time.length > 0) {
            time.pop()
        }

        time.push(Date.now())
    }

    const handleSave = (value): void => {
        const localSave = window.localStorage
        const timeStamp = Object.keys(localSave)
        const min = _.min(timeStamp)
        const max = _.max(timeStamp)

        // 判断是否修改
        if (!!max) {
            const tmpRe: {
                time: string
                value: string
            } = JSON.parse(localSave.getItem(max))
            const gap = Math.abs(_.subtract(Number(tmpRe?.time), Number(time[0])))
            const seconds = _.divide(gap, 1000)

            // 未修改就退出
            if (String(tmpRe?.time) === String(time[0])) {
                return
            }

            // 如果时间间隔小于30s也退出
            if (Math.floor(seconds) < 30) {
                return
            }
        }

        // 存储上限
        const maxLimit = 10
        if (localSave.length >= maxLimit) {
            localSave.removeItem(min)
            localSave.setItem(
                max + 1,
                JSON.stringify({
                    time: time[0],
                    value,
                }),
            )
        } else if (localSave.length === 0) {
            localSave.setItem(
                '0',
                JSON.stringify({
                    time: time[0],
                    value,
                }),
            )
        } else {
            localSave.setItem(
                max + 1,
                JSON.stringify({
                    time: time[0],
                    value,
                }),
            )
        }
    }

    useEffect(() => {
        setInterval(() => {
            const value = inputRef?.current?.value

            if (!!value) {
                handleSave(value)
            }

            console.log(value, window.localStorage)
        }, 60000)
    }, [])

    return (
        <>
            <div className='markedContainer'>
                <div className='marked'>
                    <textarea ref={inputRef} id='inputArea' onChange={onChange}></textarea>
                </div>
                <div ref={showRef} className='markedShow'></div>
            </div>

            <div className='markedContainer_btn'>
                <Button type='primary'>提交</Button>
                <Button
                    type='primary'
                    style={{
                        marginLeft: 20,
                    }}
                    onClick={() => {
                        window.localStorage.clear()
                    }}>
                    暂时保存
                </Button>
            </div>
        </>
    )
}

export default MarkDown
