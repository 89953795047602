// import { Button, Card, Col, Form, Input, Row, DatePicker } from 'antd'
import {
    // React,
    // useEffect,
    useRef,
} from 'react'
import { EditTwoTone } from '@ant-design/icons'
import MyEditor from './components'
import './style.css'

// const { RangePicker } = DatePicker

const ArticlesEdite = () => {
    const inputRef = useRef(null)
    const wangEdit = useRef(null)
    const showRef = useRef(null)
    const compareRef = useRef(null)

    const handleSubmit = values => {
        values.content = inputRef.current.getValue().value
    }

    return (
        <>
            <div className='header'>
                <h3>
                    <EditTwoTone /> Edite new Articles
                </h3>
            </div>

            <div className='editorContent'>
                <MyEditor
                    ref={wangEdit}
                    showData={html => {
                        ;(showRef.current as HTMLElement).textContent = html
                        ;(compareRef.current as HTMLElement).innerHTML = html
                        // const shadow = (showRef.current as HTMLElement).attachShadow({ mode: 'open' })
                        // shadow.innerHTML = html
                    }}
                />
                <div className='performance'>
                    <div ref={showRef}></div>
                    <div ref={compareRef}></div>
                </div>
            </div>

            <div className='submitBtn'>
                <button className='purpleBtn_status_neutral' onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </>
    )
}

export default ArticlesEdite
