/**
 * 中文的多语言
 */

export const zh_menu = {
    info: '信息',
    blog: '博客',
    resume: '简历',
    contact: '联系',
}
