import { Tag, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import { DataType } from './interface'

export const columns = (): ColumnsType<DataType> => {
    return [
        {
            title: '文章名称',
            dataIndex: 'articles',
            align: 'center',
            width: 200,
            key: 'articles',
            render: _ => _,
        },
        {
            title: '创建时间',
            dataIndex: 'date',
            align: 'center',
            width: 200,
            key: 'date',
            render: _ => moment(_).format('YYYY年MM月DD日 HH:mm:ss'),
        },
        {
            title: '发布地址',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            width: 200,
        },
        {
            title: '标签',
            key: 'tags',
            dataIndex: 'tags',
            align: 'center',
            width: 400,
            render: (_, { tags }) => (
                <>
                    {tags.map(tag => {
                        let color = tag.length > 5 ? 'geekblue' : 'green'
                        if (tag === 'loser') {
                            color = 'volcano'
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        )
                    })}
                </>
            ),
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            width: 400,
            render: (_, record) => (
                <Space size='middle'>
                    <a>Invite {record?.articles}</a>
                    <a>Delete</a>
                </Space>
            ),
        },
    ]
}
