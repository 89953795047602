import { UploadOutlined, UserOutlined, VideoCameraOutlined, DashboardOutlined, EditOutlined, TranslationOutlined, DragOutlined, FileOutlined, FileSearchOutlined } from '@ant-design/icons'
import { Archive, Table, Tag, ListTask, Easel, Mailbox, Mailbox2, Envelope, CodeSlash, Code, CodeSquare, ArrowRepeat, MusicPlayer, MusicNote, Umbrella } from 'react-bootstrap-icons'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { Link } from 'react-router-dom'
// import { Icon } from 'react-bootstrap-icons';

// interface IRoutes {
//   key?: string
//   icon?: React.ReactNode
//   danger?: boolean
//   dashed?: boolean
//   label?: string
//   children?: IRoutes[]
//   description?: string
//   path?: string
//   src?: any
// }

// type IItem = IRoutes[] & ItemType[]

export const routes = (prefix: string): ItemType[] => {
    return [
        {
            key: '/',
            icon: <DashboardOutlined />,
            label: <Link to={prefix}>默认数据</Link>,
        },
        {
            key: '/user',
            icon: <UserOutlined />,
            label: '当前用户数据',
            theme: 'light',
            children: [
                {
                    key: '/user/video',
                    icon: <VideoCameraOutlined />,
                    label: <Link to={`${prefix}/user/video`}>视频数据</Link>,
                },
            ],
        },
        {
            key: '/upload',
            icon: <UploadOutlined />,
            label: <Link to={`${prefix}/upload`}>上传</Link>,
        },
        {
            key: '/articles',
            icon: <Archive />,
            label: '文章',
            theme: 'light',
            children: [
                {
                    key: '/articles/edite',
                    icon: <EditOutlined />,
                    label: <Link to={`${prefix}/articles/edite`}>笔记</Link>,
                },
                {
                    key: '/articles/richText',
                    icon: <EditOutlined />,
                    label: <Link to={`${prefix}/articles/richText`}>富文本开发</Link>,
                },
                {
                    key: '/articles/markdown',
                    icon: <EditOutlined />,
                    label: <Link to={`${prefix}/articles/markdown`}>MarkDown</Link>,
                },
                {
                    key: '/articles/author',
                    icon: <EditOutlined />,
                    label: <Link to={`${prefix}/articles/author`}>写作组件</Link>,
                },
                {
                    key: '/articles/recommond',
                    icon: <EditOutlined />,
                    label: <Link to={`${prefix}/articles/recommond`}>文章提示</Link>,
                },
                {
                    key: '/articles/search',
                    icon: <Table />,
                    label: <Link to={`${prefix}/articles/search`}>文章表格数据</Link>,
                },
                {
                    key: '/articles/tags',
                    icon: <Tag />,
                    label: <Link to={`${prefix}/articles/tags`}>文章标签标记</Link>,
                },
                {
                    key: '/articles/preview',
                    icon: <Easel />,
                    label: <Link to={`${prefix}/articles/preview`}>文章预览</Link>,
                },
                {
                    key: '/articles/translate',
                    icon: <TranslationOutlined />,
                    label: <Link to={`${prefix}/articles/translate`}>翻译文章</Link>,
                },
                {
                    key: '/articles/languages',
                    icon: <TranslationOutlined />,
                    label: <Link to={`${prefix}/articles/languages`}>语言类</Link>,
                },
                {
                    key: '/articles/synchro',
                    icon: <ArrowRepeat />,
                    label: <Link to={`${prefix}/articles/synchro`}>同步</Link>,
                },
            ],
        },
        {
            key: '/todolist',
            icon: <ListTask />,
            itemIcon: <Archive />,
            label: <Link to={`${prefix}/todolist`}>清单</Link>,
        },
        {
            key: '/mail',
            icon: <Mailbox />,
            itemIcon: <Mailbox2 />,
            label: '邮件',
            children: [
                {
                    key: '/mail/setting',
                    icon: <Envelope />,
                    label: <Link to={`${prefix}/mail/setting`}>邮件设置</Link>,
                },
            ],
        },
        {
            key: '/codeOnline',
            icon: <CodeSlash />,
            itemIcon: <Code />,
            label: '在线编译',
            children: [
                {
                    key: '/codeOnline/online',
                    icon: <CodeSquare />,
                    label: <Link to={`${prefix}/codeOnline/online`}>在线编译</Link>,
                },
                {
                    key: '/codeOnline/codePen',
                    icon: <CodeSquare />,
                    label: <Link to={`${prefix}/codeOnline/codePen`}>CodePen</Link>,
                },
            ],
        },
        {
            key: '/music',
            icon: <MusicPlayer />,
            itemIcon: <MusicNote />,
            label: <Link to={`${prefix}/music`}>音乐</Link>,
        },
        {
            key: '/drag',
            icon: <DragOutlined />,
            itemIcon: <DragOutlined />,
            label: <Link to={`${prefix}/drag`}>拖拉拽</Link>,
        },
        {
            key: '/resume',
            icon: <Umbrella />,
            itemIcon: <Umbrella />,
            // label: <Link to={`${prefix}/drag`}>拖拉拽</Link>
            label: '简历',
            children: [
                {
                    key: '/resume/edite',
                    icon: <FileOutlined />,
                    label: <Link to={`${prefix}/resume/edite`}>编辑简历</Link>,
                },
                {
                    key: '/resume/look',
                    icon: <FileSearchOutlined />,
                    label: <Link to={`${prefix}/resume/look`}>查看简历</Link>,
                },
            ],
        },
    ]
}
