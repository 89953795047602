import { Card, Row, Col, Button, Input } from 'antd'
import { useRef, useState } from 'react'
import { imgStack, bodyImgStack, rtcStack } from 'common/error'

import './style.css'
import { EditOutlined, EnterOutlined } from '@ant-design/icons'

const Default = () => {
    const [imags, setImags] = useState<string[]>([])
    const showRef = useRef<HTMLDivElement>(null)

    return (
        <>
            <div
                style={{
                    margin: 12,
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}>
                <div className='dashboard_decorator_square'></div>
                <h2
                    style={{
                        margin: 0,
                        marginLeft: 6,
                    }}>
                    Todoist
                </h2>
            </div>
            <Row gutter={24}>
                <Col span={12}>
                    <Card
                        // title={
                        //   <h3
                        //     style={{
                        //       textAlign: "center",
                        //       fontWeight: 900,
                        //       fontFamily: "fantasy",
                        //       color: "#ff4d4f",
                        //     }}
                        //   >
                        //     重要且紧急
                        //   </h3>
                        // }
                        bordered
                        bodyStyle={{
                            background: '#f0f0f0',
                            minHeight: 200,
                        }}
                        style={{
                            margin: 12,
                        }}
                        hoverable
                        actions={[<EditOutlined key={'edit'} />]}>
                        <Input placeholder='输入重要且紧急的事情' suffix={<EnterOutlined />} />
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        // title={
                        //   <h3
                        //     style={{
                        //       textAlign: "center",
                        //       fontWeight: 900,
                        //       fontFamily: "fantasy",
                        //       color: "#faad14",
                        //     }}
                        //   >
                        //     重要不紧急
                        //   </h3>
                        // }
                        bordered
                        bodyStyle={{
                            background: '#f0f0f0',
                            minHeight: 200,
                        }}
                        style={{
                            margin: 12,
                        }}
                        hoverable
                        actions={[<EditOutlined key={'edit'} />]}>
                        <Input placeholder='输入重要且不紧急的事情' suffix={<EnterOutlined />} />
                    </Card>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Card
                        // title={
                        //   <h3
                        //     style={{
                        //       textAlign: "center",
                        //       fontWeight: 900,
                        //       fontFamily: "fantasy",
                        //       color: "#ff4d4f",
                        //     }}
                        //   >
                        //     重要且紧急
                        //   </h3>
                        // }
                        bordered
                        bodyStyle={{
                            background: '#f0f0f0',
                            minHeight: 200,
                        }}
                        style={{
                            margin: 12,
                        }}
                        hoverable
                        actions={[<EditOutlined key={'edit'} />]}>
                        <Input placeholder='输入不重要且紧急的事情' suffix={<EnterOutlined />} />
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        // title={
                        //   <h3
                        //     style={{
                        //       textAlign: "center",
                        //       fontWeight: 900,
                        //       fontFamily: "fantasy",
                        //       color: "#faad14",
                        //     }}
                        //   >
                        //     重要不紧急
                        //   </h3>
                        // }
                        bordered
                        bodyStyle={{
                            background: '#f0f0f0',
                            minHeight: 200,
                        }}
                        style={{
                            margin: 12,
                        }}
                        hoverable
                        actions={[<EditOutlined key={'edit'} />]}>
                        <Input placeholder='输入不重要且不紧急的事情' suffix={<EnterOutlined />} />
                    </Card>
                </Col>
            </Row>

            <Card
                title='错误收集'
                style={{
                    margin: 12,
                }}>
                <span
                    onClick={() => {
                        const a = 1
                        a[0] = 2
                    }}>
                    123
                </span>
                <Button
                    onClick={() => {
                        const a = 1
                        a[0] = 2
                    }}>
                    Click
                </Button>
                <Row gutter={24} align={'middle'} justify={'center'}>
                    <Col span={8}>1</Col>

                    <Col span={8}>2</Col>

                    <Col span={8}>3</Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Card>1</Card>
                    </Col>

                    <Col span={12}>
                        <Card>2</Card>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Card>1</Card>
                    </Col>
                </Row>

                <Row ref={showRef} gutter={24}>
                    <Button
                        type='primary'
                        onClick={() => {
                            setImags([...imgStack, ...bodyImgStack, ...rtcStack])
                        }}>
                        Show error
                    </Button>
                    <Col span={18}>
                        {imags?.map((ci, index) => (
                            <img className='errorImages' src={ci} key={index + '_imag'} />
                        ))}
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default Default
